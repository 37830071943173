vue
<template>
  <div>
    <div class="p-grid">
      <div class="p-col-12 p-d-flex p-jc-center">
        <p class="title-bold">Hilos tensores PDO</p>
      </div>
    </div>

    <div class="p-grid">
      <div class="p-col-12 p-d-flex p-jc-center">
        <img class="img-fluid" alt="hilos
                tensores" src="../../assets/medicinaEsteticaHilosTensores.jpg" />
      </div>

      <div class="p-col-10 p-offset-1 p-d-flex p-jc-center">
        <Card styleClass="p-card-shadow">
          <template #content>
            <p>
              Con los años nuestra piel va perdiendo colágeno y elastina y los <span class="p-text-bold">hilos
                tensores</span> son una magnífica opción para poder realizarnos un <span
                class="p-text-bold">lifting</span> sin tener que recurrir a una molesta cirugía. Los hilos tensores son
              conocidos para tratamientos faciales pero también se pueden utilizar en otras zonas como el cuello, y
              también distintas zonas <span class="p-text-bold">corporales</span> como abdomen, brazos, glúteos y muslos.
            </p>
            <p>
              En el tratamiento facial podemos conseguir elevar las cejas, eliminar y líneas de expresión y principalmente
              eliminar la flacidez alrededor de las mejillas, la línea de la mandíbula y el cuello, de este modo
              prevenimos la continuidad de la flacidez y aportamos <span class="p-text-bold">firmeza</span>. En lo
              referente a lo corporal, los Hilos Tensores son utilizados para elevar y dar <span
                class="p-text-bold">firmeza</span> a los glúteos, tensar la flacidez de los brazos para levantar los
              senos, o reafirmar el abdomen.
            </p>
            <p>
              Este tratamiento es <span class="p-text-bold">ambulatorio</span>, mínimamente invasivo, no es doloroso y no
              requiere cirugía, es decir, tras finalizar el tratamiento el paciente puede llevar a cabo sus hábitos
              diarios. La única complicación es que pueden aparecer <span class="p-text-bold">hematomas</span> y algo de
              inflamación que desaparecerá tomando los dos o tres días posteriores antiinflamatorios y se reabsorben
              totalmente con el paso del tiempo, con resultados duraderos de hasta 18 meses.
            </p>
            <p>
              Llega a su total eficacia transcurridas dos semanas desde el tratamiento, y pasados 6 u 8 meses es
              recomendable volverlo a aplicar.
            </p>
          </template>

          <template #footer>
            <span>Para más información no dude en ponerse en contacto con nuestro
              Centro médico al 637 857 384.</span>
          </template>
        </Card>
      </div>
    </div>
  </div>
</template>

<script>
import Card from 'primevue/card';
export default {
  name: 'HilosPdoVue',
  data() {
    return {
      // Propiedades de datos del componente
    };
  },
  components: {
    Card
  },
  methods: {
    // Métodos del componente
  },
  computed: {
    // Propiedades computadas del componente
  },
  created() {
    // Lógica que se ejecuta cuando el componente se crea
  },
  mounted() {
    // Lógica que se ejecuta cuando el componente se monta en el DOM
  },
  destroyed() {
    // Lógica que se ejecuta cuando el componente se destruye
  },
};
</script>

<style scoped>
.title-bold {
  font-size: 30px;
  font-weight: bold
}

.img-fluid {
  width: 100%;
  height: auto;
}

@media (min-width: 576px) {
  .img-fluid {
    max-width: 50%;
    height: auto;
  }
}
</style>